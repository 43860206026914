import React, { useEffect } from "react";
import { useState,useContext } from "react";
import Popup from "./../firdous/Popup";
import Alert from '@material-ui/lab/Alert';
import { Button } from "@material-ui/core";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Divider from "@material-ui/core/Divider";
import { UserContext } from '../../contexts/UserContext';
import  answerQuestion  from '../../utils/answerQuestion';
import  answerSubmit  from '../../utils/answerSubmit';
import { navigate } from "@storybook/addon-links";
import {useNavigate} from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  detailText: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.primary.dark,
    whiteSpace: "pre-line",
  },
  detailQuestionText: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.primary.dark,
    whiteSpace: "pre-line",
    marginBottom: "1rem",
    marginTop: "1.8rem",
  },
  tabsAppBar: {
    boxShadow: "none",
  },
  tabNames: {
    textTransform: "none",
  },
  detailOptionText: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.primary.dark,
    whiteSpace: "pre-line",
  },
  detailOptionRoot: {
    marginBottom: "0.2rem",
  },
  radioRoot: {
    padding: "8px 9px",
  },
  optionGroup: {
    marginBottom: "1rem",
  },
  audioStyles: {
    width: "100%",
  },
}));

function QuestionDisplay({ qsObj = [], moduleId, course, moduleDetails }) {
  const [values, setValues] = useState([]);

  const optionsLetter = ["A", "B", "C", "D"];
  const { user } = useContext(UserContext);
  const [lastChanged, setLastChanged] = useState({ id: -1, value: "" });
  const [message, setMessage] = useState("");
  const [ error, setError] = useState([])
  const navigate = useNavigate();

  useEffect(()=>{
    setValues(qsObj?.map((item) => ({
      course_question_id: item?.id,
      option_id: item.answer,
    })))
  },[qsObj])
  async function setAnswerQuestion(course_question_id, option_id) {
    await answerQuestion(course_question_id, user?.selectedUser?.id, option_id, moduleId, course?.id, moduleDetails?.module_type);
  }

  async function setAnswerSubmit() {
    const unAnswered= values.filter((item)=>item.option_id==='0')
    if(unAnswered.length){
      setError(unAnswered.map(item=>item.course_question_id))
      return
    }
    await answerSubmit(values, user?.selectedUser?.id, moduleId, course?.id, moduleDetails?.module_type);
  }

  useEffect(() => {
    if (lastChanged.value === "" || lastChanged.id === -1 || !qsObj) return;

    const updatedValues = values.map((value, index) =>{
        return index === lastChanged.id
        ? { course_question_id: qsObj[lastChanged.id]?.id, option_id: lastChanged.value }
        : value
    }
      
    );
    setValues(updatedValues);
    setAnswerQuestion(updatedValues[lastChanged.id]?.course_question_id, lastChanged.value);
  }, [lastChanged, qsObj]);

  const classes = useStyles();

  const handleChange = (id, event) => {
    const newValue = event.target.value;
    if (newValue !== values[id]?.option_id) {
      setLastChanged({ id, value: newValue });
    }
  };

  return (
    <div>
      {qsObj.map((item, index) => (
       <div
       key={item?.id}
       style={{
         backgroundColor: error.includes(item.course_question_id) ? '#FF0000' : 'transparent',
         // Add other styles as needed
       }}
     >
          <Typography className={classes.detailQuestionText}>
            {index + 1}) {item?.question}
          </Typography>
          <audio controls className={classes.audioStyles}>
            <source src={`${item?.question_audio_url}`} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
          <FormControl component="fieldset" classes={{ root: classes.optionGroup }}>
            <RadioGroup
              aria-label="answer"
              name={`answer${index}`}
              defaultValue={item.answer}
              onChange={(e) => handleChange(index, e)}
            >
              {item?.options?.map((option, i) => (
                <FormControlLabel
                  key={option?.option_id}
                  classes={{
                    root: classes.detailOptionRoot,
                    label: classes.detailOptionText,
                  }}
                  value={`${option?.option_id}`}
                  control={<Radio classes={{ root: classes.radioRoot }} />}
                  label={optionsLetter[i] + ") " + option?.option}
                />
              ))}
            </RadioGroup>
          </FormControl>
          <Divider />
        </div>
      ))}

      <Grid container spacing={2}>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={() => {
              if (values.some(val => val.option_id === "")) {
                setMessage("Answer All Questions");
                return;
              }
              setAnswerSubmit();
              if(moduleDetails?.module_type==="Final")
              setMessage(`താങ്കളുടെ ഫൈനൽ പരീക്ഷയുടെ ഉത്തരങ്ങൾ സ്വീകരിച്ചിരിക്കുന്നു`);
              else if(moduleDetails?.module_type==="Model")
                setMessage(`താങ്കളുടെ മോഡൽ പരീക്ഷയുടെ ഉത്തരങ്ങൾ സ്വീകരിച്ചിരിക്കുന്നു`);
              else
              setMessage(`താങ്കളുടെ ക്ലാസ് ടെസ്റ്റ് ${moduleDetails?.module_no} ൻ്റെ ഉത്തരങ്ങൾ സ്വീകരിച്ചിരിക്കുന്നു`);
              navigate('/')


            }}
            style={{
                textTransform: 'none' 
            }} 
          >
            Submit
          </Button>
        </Grid>
        
        <Grid item xs={4}></Grid>
      </Grid>
      {message && (
        <Alert style={{ marginTop: "10px" }} variant="filled" severity="success">
          {message}
        </Alert>
      )}
    </div>
  );
}

export default QuestionDisplay;

