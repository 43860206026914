import axios from 'axios';
import config from './config.json';
const answerSubmit = async ( answer, userId,module_id,course_id,module_type) => {
    let data = new FormData();
    answer.map((ans,ind) => (
        data.append(`answer[${[ind]}][course_question_id]`, ans.course_question_id)
    ))
    data.append("course_candidate_id", userId)
    answer.map((ans,ind) => (
        data.append(
            `answer[${[ind]}][option_id]`,
            Number(ans.option_id)
        )
    ))
    data.append('module_id', module_id);
    data.append("course_id",course_id)
    data.append('module_type',module_type)

    const res = await axios({
        method: "post",
        url: `${config.API_URL}${config.ANSWER_SUBMIT_API_URI}`,
        data: data,
        headers: { 
            "Content-Type": "multipart/form-data", 
            'Authorization': `Bearer ${localStorage.getItem('token')}`, 
         },
    })
    return res.data;
}

export default answerSubmit;