import React, { useState, useMemo } from 'react';
import { Modal, Box, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

const YouTubeModal = ({ open, onClose, videoId }) => {
  const [isPlayerLoaded, setIsPlayerLoaded] = useState(false);

  const boxStyles = useMemo(() => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: '800px',
    bgcolor: 'background.paper',
    borderRadius: '8px',
    boxShadow: 24,
    p: 0,
  }), []);

  const closeButtonStyles = useMemo(() => ({
    position: 'absolute',
    top: 8,
    left: 8,
    backgroundColor: 'rgba(255, 0, 0, 0.6)',
    color: 'white',
    '&:hover': {
      backgroundColor: 'darkred',
    },
  }), []);

  const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="youtube-modal-title"
      aria-describedby="youtube-modal-description"
    >
      <Box sx={boxStyles}>
        <IconButton
          onClick={onClose}
          sx={closeButtonStyles}
        >
          <CloseIcon />
        </IconButton>
        {!isPlayerLoaded ? (
          <Box
            sx={{
              cursor: 'pointer',
              position: 'relative',
              paddingBottom: '56.25%', // 16:9 aspect ratio
              backgroundImage: `url(${thumbnailUrl})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              width: '100%',
              height: 0,
            }}
            onClick={() => setIsPlayerLoaded(true)}
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '50px',
                height: '50px',
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 5v14l11-7L8 5z" />
              </svg>
            </Box>
          </Box>
        ) : (
          <iframe
            width="100%"
            height="450px"  // Set a fixed height for the video
            src={`https://www.youtube.com/embed/${videoId}?autoplay=1&vq=small`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{ borderRadius: '8px' }}
          ></iframe>
        )}
      </Box>
    </Modal>
  );
};

export default YouTubeModal;
