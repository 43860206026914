import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
export default function GenderField({value,onChange,data=[]}) {
  return (
    <FormControl >
      <ToggleButtonGroup
        color="primary"
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        aria-label="Platform"
        exclusive
        value={value}
        onChange={(e)=>onChange(e.target.value)}
      >
        {data.map((item,index)=>{
          return <ToggleButton key={index} value={item.key}>{item.value}</ToggleButton>
        })}
      </ToggleButtonGroup>
    </FormControl>
  );
 
}