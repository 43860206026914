import React, { useContext } from "react";
import { AppBar, Typography, IconButton, Toolbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// import { MenuIcon } from '@material-ui/icons'
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
// import ExitToAppIcon from '@material-ui/icons/ExitToApp';
// import PersonIcon from '@material-ui/icons/Person';
// import ShareIcon from '@material-ui/icons/Share';
import { UserContext } from "../contexts/UserContext";
import Modal from "./firdous/modal";
import { useLocation, useNavigate } from "react-router-dom";
import { Box } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  appbar: {
    zIndex: 2000,
  },
}));

function NavBar() {
  const { user, setUser } = useContext(UserContext);
  const classes = useStyles();
  const { pathname } = useLocation();
  const history = useNavigate();
  return (
    <>
      <AppBar position="fixed" className={classes.appbar}>
        <Toolbar>
          {pathname !== "/" ? (
            <>
              <IconButton
                edge="start"
                className=""
                color="inherit"
                aria-label="open drawer"
                onClick={() => {
                  history("/");
                }}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography color="inherit">Course</Typography>
            </>
          ) : (
            ""
          )}
          {pathname === "/home" ? (
            <>
              <IconButton
                edge="start"
                className=""
                color="inherit"
                aria-label="open drawer"
                onClick={() => history("/membercourselist")}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography color="inherit">Dashboard</Typography>
            </>
          ) : (
            ""
          )}
          <div className={classes.grow} />
          {/* <div> */}
          {/* <IconButton
            aria-label="show more"
            aria-haspopup="true"
            color="inherit"
          >
            <ShareIcon />
          </IconButton> */}
          {/* </div> */}
          {/* <div> */}
          {/* <IconButton
            aria-label="show more"
            aria-haspopup="true"
            color="inherit"
          > */}
           <Box
          sx={{
            mx: 1,
            fontSize:20
          }}
        >
            {user.selectedUser?.name ? `${user.selectedUser?.name.toUpperCase()}`:''} {user.selectedUser?.roll_number? `(${user.selectedUser?.roll_number})`:''}
            </Box>
          {user?.selectedUser ? <Modal />:<></>}
          {/* <ExitToAppIcon /> */}
          {/* </IconButton> */}
          {/* <Menu /> */}
          {/* </div> */}
        </Toolbar>
      </AppBar>
    </>
  );
}

export default NavBar;
