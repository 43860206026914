
import {useEffect, useState} from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
const DOBField=({onChange})=>{
    const [dob,setDOB]=useState('1800-01-01')
    const [age,setAge]=useState(0)
    useEffect(()=>{
        if (dob!=='1800-01-01') {
            const birthDate = dayjs(dob);
            const today = dayjs();
            const calculatedAge = today.diff(birthDate, 'year');
            setAge(calculatedAge);
            onChange(dob,"dob")
            onChange(age,"age")
          }
    },[age, dob])
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DateField']}>
            <DateField
              label="Date Of Birth"
              defaultValue={dayjs(dob)}
              format="DD-MM-YYYY"
              onChange={(newValue) => setDOB(newValue ? newValue.format('YYYY-MM-DD') : '')}
              inputProps={{ sx: {
                padding: '8.5px 14px', 
              }, }}
              sx={{
                fontSize: '14px',
                color: 'rgba(0, 0, 0, 0.3)',
                fontFamily: '"Noto Sans Malayalam", sans-serif', // Use an inbuilt Malayalam font
                // Set font size for the selected item
              }}

              InputLabelProps={{
                shrink: true, // Keeps the label always visible (shrinks it when focused)
                sx: {
                  fontSize: '16px', // Adjust the label font size
                  fontFamily: '"Noto Sans Malayalam", sans-serif', // Use an inbuilt Malayalam font
                  fontWeight:"bold",
                 
                  
              }}}
            />
          </DemoContainer>
        </LocalizationProvider>
      );
}
export default DOBField;